import { getClient } from 'app/lib/analytics';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { EVENTS } from 'app/lib/analytics/constants';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import { get } from 'lodash';
import { submitProviderDetails } from 'redux/modules/HomeAudit/actions';
import { SERVICES } from 'redux/modules/HomeAudit/constants';
import * as actions from './actions';
import { putBroadbandRequest as putBroadbandRequestAction } from './actions';
import { BroadbandApi } from './api';
import { OrderSummaryApi } from '../OrderSummary/api';
import {
  GET_BROADBAND_REQUEST,
  NO_CALL_PACKAGE,
  PACKAGE_NAME_EERO,
  PUT_BROADBAND_REQUEST,
  PUT_BROADBAND_SUCCESS,
  UPDATE_HOMEPHONE_OPTION,
  TECHNOLOGY_FTTC,
  TECHNOLOGY_FTTP,
  TECHNOLOGY_LLU,
  TECHNOLOGY_SOGEA,
  NO_ROUTER_ID,
} from './constants';
import { getSummaryRequest } from '../OrderSummary/actions';
import getBroadbandTariffs from './service/getBroadbandTariffs';
import putBroadband from './service/putBroadband';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';

export const enrichTariffs = (tariffs) => {
  if (!FLAGS.FIXED_LINE_PRICE_CHANGE) return tariffs;
  const enrichStandardTariffCost = '£32.99 from 1st Jan 2022';
  const enrichDiscountTariffCost = '£29.69 from 1st Jan 2022';
  return tariffs.map((t) => {
    if (
      t.tariffLabel === 'Standard' &&
      get(t, 'contract.monthlyPrice.value') === 1100
    ) {
      t.contract.extraCostInfo = t.contract.discountMonthlyPrice
        ? enrichDiscountTariffCost
        : enrichStandardTariffCost;
    }
    return t;
  });
};

function* setTechnology() {
  const hasFttp = yield select(BroadbandApi.hasFttpTariffs);
  const hasSogeaTariffs = yield select(BroadbandApi.hasSogeaTariffs);
  const hasCopperTariffs = yield select(BroadbandApi.hasCopperTariffs);

  let technology = null;

  if (hasFttp) {
    technology = TECHNOLOGY_FTTP;
  } else if (hasSogeaTariffs) {
    technology = TECHNOLOGY_SOGEA;
  } else if (hasCopperTariffs) {
    technology = TECHNOLOGY_LLU;
  } else {
    technology = TECHNOLOGY_FTTC;
  }

  yield put(actions.selectBroadbandTechnology(technology));
}

function* getBroadbandTariffsRequest() {
  yield put(actions.selectBroadbandTechnology(null));

  try {
    const { client: analytics } = getClient();
    const { data } = yield call(getBroadbandTariffs);
    let tariffs = data ? data.tariffs : null;
    const tariffsNoRouterRemoved = tariffs.map((tariff) => {
      const equipmentPackage =
        tariff.equipmentPackage.filter((e) => e.id !== NO_ROUTER_ID) || [];
      return {
        ...tariff,
        equipmentPackage: equipmentPackage,
      };
    });
    if (tariffsNoRouterRemoved) {
      tariffs = enrichTariffs(tariffsNoRouterRemoved);
    }

    const hasGoldDiscount = Boolean(data?.hasGoldDiscount);
    const requiresNewLine = Boolean(data?.requiresNewLine);
    const callPackages = [
      ...normaliseCallPackages(data.callPackages),
      NO_CALL_PACKAGE,
    ];

    yield put(
      actions.getBroadbandRequestSuccess(
        tariffs,
        hasGoldDiscount,
        callPackages,
        requiresNewLine
      )
    );
    yield call(setTechnology);

    const hasFttp = yield select(BroadbandApi.hasFttpTariffs);
    const isOnlyFttp = yield select(BroadbandApi.isOnlyFttp);
    let packageType = 'only_ultra';
    if (hasFttp) {
      packageType = isOnlyFttp ? 'only_full_fibre' : 'all';
    }
    analytics.track(EVENTS.BROADBAND_PACKAGES_PRESENTED, {
      packages: packageType,
    });
  } catch (error) {
    Sentry.log(error, ERRORS.GET_BROADBAND_TARIFFS);
    yield put(actions.getBroadbandRequestFailure());
    const message = `We encountered an error while fetching the broadband tariffs. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }
}

function* putBroadbandRequest(action) {
  const { equipment } = action;
  let context = 'broadband';
  if (action.context) {
    context = action.context;
  }
  const { client: analytics } = getClient();
  let selectedEeroDevices = null;

  if (equipment === PACKAGE_NAME_EERO) {
    selectedEeroDevices = yield select(BroadbandApi.getSelectedEeroDevices);
  }
  const includeHomephone = yield select(
    (state) => !BroadbandApi.homephoneNotRequired(state)
  );

  try {
    const broadbandOptionsSelectedState = yield select(
      analyticsSelectors[EVENTS.BROADBAND_QUOTE_ACCEPTED]
    );
    analytics.track(
      EVENTS.BROADBAND_QUOTE_ACCEPTED,
      broadbandOptionsSelectedState
    );
    yield call(
      putBroadband,
      action.tariff,
      action.equipment, //routerName
      selectedEeroDevices,
      includeHomephone,
      action.homephoneOptions
    );
    yield put(submitProviderDetails(SERVICES.BROADBAND));
    yield put(actions.putBroadbandSuccess(context));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_BROADBAND_DETAILS);
    yield put(actions.putBroadbandFailure(error));
    const message = `We encountered an error while posting your broadband service details. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }
}

function* handlePutBroadbandSuccess(action) {
  const { context } = action;

  try {
    const application = yield select(OrderSummaryApi.getApplication);
    if (context === 'update_landline_number') {
      yield put(getSummaryRequest(false, application.completed));
    }
  } catch (error) {
    Sentry.log(error, ERRORS.PUT_BROADBAND_SUCCESS);
  }
}

function* handleUpdateHomephoneOption(action) {
  const { value, context } = action;
  if (context === 'update_landline_number') {
    try {
      const selectedTariffId = yield select(BroadbandApi.getSelectedTariffId);
      //selected eeros devices is calculated in putBroadbbandrequest, so going to give it 0
      const selectedEeroDevices = 0;
      const equipment = yield select(BroadbandApi.getSelectedEquipmentPackage);
      const homeOptions = yield select(
        BroadbandApi.getSelectedHomephoneOptions
      );
      const homephoneOptionsUpdated = {
        ...homeOptions,
        isVirginMedia: false,
        landlineNumber: value,
      };
      yield put(
        putBroadbandRequestAction(
          selectedTariffId,
          equipment[1].name,
          selectedEeroDevices,
          homephoneOptionsUpdated,
          context
        )
      );
    } catch (error) {
      Sentry.log(error, ERRORS.UPDATE_BROADBAND_DETAILS);
      yield put(actions.putBroadbandFailure(error));
      const message = `We encountered an error while posting your broadband service details. ${error}`;
      yield put(openErrorGlobalDialog(message));
    }
  }
}

export const normaliseCallPackages = (callPackages) => {
  if (!callPackages) return [];
  return callPackages.map(({ id, ...callPackage }) => ({
    id: String(id),
    ...callPackage,
  }));
};

export function* combinedSagas() {
  yield takeLatest(GET_BROADBAND_REQUEST, getBroadbandTariffsRequest);
  yield takeLatest(PUT_BROADBAND_REQUEST, putBroadbandRequest);
  yield takeLatest(PUT_BROADBAND_SUCCESS, handlePutBroadbandSuccess);
  yield takeLatest(UPDATE_HOMEPHONE_OPTION, handleUpdateHomephoneOption);
}
