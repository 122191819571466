import {
  CLEAR_BROADBAND_OPTIONS,
  GET_BROADBAND_REQUEST,
  GET_BROADBAND_REQUEST_FAILURE,
  GET_BROADBAND_REQUEST_SUCCESS,
  PUT_BROADBAND_FAILURE,
  PUT_BROADBAND_REQUEST,
  PUT_BROADBAND_SUCCESS,
  SET_BROADBAND_MORE_INFO,
  SET_EERO_DIALOG_OPEN,
  UPDATE_HOMEPHONE_OPTION,
  UPDATE_ROUTER_SELECTION,
  UPDATE_SELECTED_EERO_DEVICES,
  UPDATE_TARIFF_SELECTION,
} from './constants';
import * as types from './types';

export const getBroadbandRequest = () => ({ type: GET_BROADBAND_REQUEST });
export const getBroadbandRequestSuccess = (
  tariffs,
  hasGoldDiscount,
  callPackages,
  requiresNewLine
) => ({
  type: GET_BROADBAND_REQUEST_SUCCESS,
  tariffs,
  hasGoldDiscount,
  callPackages,
  requiresNewLine,
});
export const getBroadbandRequestFailure = () => ({
  type: GET_BROADBAND_REQUEST_FAILURE,
});

export const putBroadbandRequest = (
  tariff,
  equipment,
  selectedEeroDevices,
  homephoneOptions,
  context
) => ({
  type: PUT_BROADBAND_REQUEST,
  tariff,
  equipment,
  selectedEeroDevices,
  homephoneOptions,
  context,
});
export const putBroadbandSuccess = (context) => ({
  type: PUT_BROADBAND_SUCCESS,
  context,
});
export const putBroadbandFailure = (error) => ({
  type: PUT_BROADBAND_FAILURE,
  error,
});

export const updateBroadbandRouter = (routerName) => ({
  type: UPDATE_ROUTER_SELECTION,
  routerName,
});
export const updateBroadbandTariff = (tariff, routerName) => {
  return {
    type: UPDATE_TARIFF_SELECTION,
    tariff,
    routerName,
  };
};
export const updateSelectedEeroDevices = (selectedEeroDevices) => ({
  type: UPDATE_SELECTED_EERO_DEVICES,
  selectedEeroDevices,
});

export const setBroadBandMoreInfo = (open) => ({
  type: SET_BROADBAND_MORE_INFO,
  open,
});
export const setEeroDialogOpen = (open) => ({
  type: SET_EERO_DIALOG_OPEN,
  open,
});

export const clearBroadbandOptions = () => ({
  type: CLEAR_BROADBAND_OPTIONS,
});

export const selectBroadbandTechnology = (technology) => ({
  type: types.BROADBAND_SET_TECHNOLOGY,
  technology,
});

export const updateHomephoneOption = (option, value, context) => ({
  type: UPDATE_HOMEPHONE_OPTION,
  option,
  value,
  context,
});

export const preselectSuccess = (preselectState) => ({
  type: types.PRESELECT_SUCCESS,
  preselectState,
});
