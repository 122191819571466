import { formatPrice } from 'redux/utils/formatter';
import {
  DEVICE_EERO,
  PACKAGE_NAME_EERO,
  UI_PACKAGE_NAME_EERO,
  PACKAGE_NAME_UW_HUB,
} from 'redux/modules/Broadband/constants';

export const groupEquipment = (devices = []) => {
  let eeroDevices = [];
  let router;
  let totalMonthlyPrice = { ...devices[0].monthlyCharge, value: 0 };

  devices.forEach((equipment) => {
    if (equipment.name === DEVICE_EERO) eeroDevices.push(equipment);
    else router = equipment;
    totalMonthlyPrice.value += equipment.monthlyCharge.value;
  });
  return { eeroDevices, router, totalMonthlyPrice };
};

export const getStandardRouterCharge = (equipmentPackage) =>
  equipmentPackage.find((equipment) => equipment.name === PACKAGE_NAME_UW_HUB)
    ?.oneOffCharge?.value || 0;

// NOTE: This is indicative of price with UW Wi-Fi Hub, will not change depending what device is chosen
const calculateTotalPrice = (
  price,
  length,
  equipmentPackage,
  promoLength = 0,
  promoTotalPrice = 0
) => {
  if (!price?.value) {
    return null;
  }

  const deviceCharge = getStandardRouterCharge(equipmentPackage);

  if (!promoLength) {
    return {
      value: price.value * length + deviceCharge,
      exponent: 2,
      currency: 'GBP',
    };
  }

  const standardPeriodPrice = price.value * (length - promoLength);
  return {
    value: standardPeriodPrice + promoTotalPrice,
    exponent: 2,
    currency: 'GBP',
  };
};

export const mapTariffs = (tariffs = []) => {
  return tariffs.map((tariff) => {
    let lineRentalPrice = {};
    if (tariff.contract.discountLineRental) {
      lineRentalPrice = tariff.contract.discountLineRental;
    } else if (tariff.contract.lineRental) {
      lineRentalPrice = tariff.contract.lineRental;
    }

    const lineRentalPriceRaw = lineRentalPrice ? lineRentalPrice.value : 0;
    const monthlyPrice = tariff.contract.monthlyPrice;
    const monthlyPriceWithLineRental = {
      ...monthlyPrice,
      value: monthlyPrice.value + lineRentalPriceRaw,
    };
    tariff.contract.monthlyPriceWithLineRental = monthlyPriceWithLineRental;

    const totalContractPrice = calculateTotalPrice(
      monthlyPriceWithLineRental,
      tariff.contract.length,
      tariff.equipmentPackage
    );

    if (totalContractPrice) {
      tariff.contract.totalContractPrice = totalContractPrice;
    }

    if (lineRentalPriceRaw) {
      tariff.contract.lineRentalIncluded = true;
      tariff.contract.lineRentalEndPrice = lineRentalPrice;
    }

    if (tariff.contract.promo) {
      const promoTotalPrice =
        tariff.contract.promo.length * tariff.contract.promo.monthlyPrice.value;
      const totalContractPriceWithPromo = calculateTotalPrice(
        monthlyPriceWithLineRental,
        tariff.contract.length,
        tariff.equipmentPackage,
        tariff.contract.promo.length,
        promoTotalPrice
      );

      if (totalContractPriceWithPromo) {
        tariff.contract.totalContractPriceWithPromo = totalContractPriceWithPromo;
      }

      const monthlyPromoPrice = {
        ...tariff.contract.promo.monthlyPrice,
        value: tariff.contract.promo.monthlyPrice.value + lineRentalPriceRaw,
      };
      tariff.contract.promo.monthlyPriceWithLineRental = monthlyPromoPrice;
    }

    return tariff;
  });
};

export const getTariffPriceWithLineRental = (tariff) => {
  return tariff.contract.promo
    ? tariff.contract.promo.monthlyPriceWithLineRental
    : tariff.contract.monthlyPriceWithLineRental;
};

export const getTariffName = (name = '') => {
  const re = /(\w+\s?\w+)(?:\s(\d+|[^\w\s]))?/gm;
  const [, title, special] = re.exec(name) || [];
  return { title, special };
};

export const getRouterName = (router) => {
  if (router.name !== PACKAGE_NAME_EERO) return router.name;
  return UI_PACKAGE_NAME_EERO;
};

export const getTotalEerosPrice = (
  equipmentBase,
  equipmentEero,
  selectedEeroDevices
) => {
  const { value: basePrice } = equipmentBase.monthlyCharge;
  const { value: eeroPrice } = getEerosPrice(
    equipmentEero,
    selectedEeroDevices
  );
  return {
    ...equipmentBase.monthlyCharge,
    value: basePrice + eeroPrice,
  };
};

export const getEerosPrice = (equipmentEero, selectedEeroDevices) => {
  const { value } = equipmentEero.monthlyCharge;
  return {
    ...equipmentEero.monthlyCharge,
    value: value * selectedEeroDevices,
  };
};

export const getRouterPrice = (router, selectedEeroDevices = 0) => {
  if (router.name !== PACKAGE_NAME_EERO) return router.monthlyCharge;

  const equipmentBase = router.broadbandEquipment.find(
    (e) => e.name !== DEVICE_EERO
  );
  const equipmentEero = router.broadbandEquipment.find(
    (e) => e.name === DEVICE_EERO
  );
  return getTotalEerosPrice(equipmentBase, equipmentEero, selectedEeroDevices);
};

const getCallPackageValue = (callPackage) => callPackage?.cost?.value || 0;

export const getFullPriceWithPromo = (
  tariff,
  router,
  selectedEeroDevices = 0,
  callPackage,
  requiresLineActivation
) => {
  const tariffPrice = getTariffPriceWithLineRental(tariff);
  const routerPrice = getRouterPrice(router, selectedEeroDevices);
  const callPackageValue = getCallPackageValue(callPackage);
  const lineActivationValue = requiresLineActivation
    ? lineActivationPrice.value
    : 0;
  return {
    ...tariffPrice,
    value:
      tariffPrice.value +
      routerPrice.value +
      callPackageValue +
      lineActivationValue,
  };
};

export const getFullPriceNoPromo = (
  tariff,
  router,
  selectedEeroDevices = 0,
  callPackage
) => ({
  ...tariff.contract.monthlyPriceWithLineRental,
  value:
    tariff.contract.monthlyPriceWithLineRental.value +
    getRouterPrice(router, selectedEeroDevices).value +
    getCallPackageValue(callPackage),
});

export const getRouterPriceFormatted = (router, selectedEeroDevices = 0) => {
  const price = getRouterPrice(router, selectedEeroDevices);
  if (price.value === 0) return 'Free';

  return formatPrice(price);
};

export const lineActivationPrice = { currency: 'GBP', value: 100, exponent: 2 };
