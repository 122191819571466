export const corporatePartnersList = [
  'AD7394',
  'AE0697',
  'AE0764',
  'AE2780',
  'AD5402',
  'AE3005',
  'AE3454',
  'AF2163',
  'AF2164',
  'AF2166',
  'AF2168',
  'AG1995',
  'AG1500',
  'AG1499',
  'AG1997',
  'AH0919',
  'AH1088',
  'AH3709',
  'AH7679',
  'AH7680',
  'AH7682',
  'AI8706',
  'AI8704',
  'AH7685',
  'AD6564',
  'AG3800',
  'AH2355',
  'AI4090',
  'AE3854',
  'AE8162',
  'AE8163',
  'AE8164',
  'AE4018',
  'AE8352',
  'AC5659',
  'AE7885',
  'AF6399',
  'AF8865',
  'AF8982',
  'AD6399',
  'AF9032',
  'AF9738',
  'AD8516',
  'AD5183',
  'AC9260',
  'AF7166',
  'N59563',
  'AG1786',
  'AG3801',
  'AH0613',
  'AJ0473',
  'AG1778',
  'AG3198',
  'AA8638',
  'AG2370',
  'AG5032',
  '9792540',
  '9798621',
  'AG6252',
  'AG6798',
  'AG6987',
  'AG8133',
  'AG8141',
  'AG8318',
  'AG9521',
  'AG9524',
  'AF3861',
  'AH0770',
  'AH1459',
  'AH1462',
  'AD0711',
  'AH2133',
  'AH2137',
  'AH2139',
  'AH3116',
  'AH3174',
  'AH4231',
  'AH4909',
  'AH5424',
  'AH4988',
  'AH4992',
  'AH4993',
  'AH4994',
  'AH2129',
  'AH4807',
  'AH7846',
  'AI3415',
  'AI3708',
  'AI4128',
  'AI4960',
  'AI8293',
  'AI9191',
  'AJ1975',
  'AJ1977',
  'AJ6227',
  'AK0412',
  'AK1739',
  'AL6449',
  'AI9370',
];
