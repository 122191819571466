export const STORE_NAME = 'Broadband';

export const UPDATE_ROUTER_SELECTION = 'UPDATE_ROUTER_SELECTION';
export const UPDATE_TARIFF_SELECTION = 'UPDATE_TARIFF_SELECTION';
export const UPDATE_SELECTED_EERO_DEVICES = 'UPDATE_SELECTED_EERO_DEVICES';
export const UPDATE_HOMEPHONE_OPTION = 'UPDATE_HOMEPHONE_OPTION';

export const GET_BROADBAND_REQUEST = 'GET_BROADBAND_REQUEST';
export const GET_BROADBAND_REQUEST_SUCCESS = 'GET_BROADBAND_REQUEST_SUCCESS';
export const GET_BROADBAND_REQUEST_FAILURE = 'GET_BROADBAND_REQUEST_FAILURE';

export const PUT_BROADBAND_REQUEST = 'PUT_BROADBAND_REQUEST';
export const PUT_BROADBAND_SUCCESS = 'PUT_BROADBAND_SUCCESS';
export const PUT_BROADBAND_FAILURE = 'PUT_BROADBAND_FAILURE';

export const CLEAR_BROADBAND_OPTIONS = 'CLEAR_BROADBAND_OPTIONS';

export const TARIFF_STANDARD = 'standard';
export const TARIFF_ULTRA = 'ultra';
export const TARIFF_ULTRA_PLUS = 'ultraplus';
export const TARIFF_FIBRE_40 = 'fibre40';
export const TARIFF_FIBRE_80 = 'fibre80';
export const TARIFF_FTTP_100 = 'fullfibre100';
export const TARIFF_FTTP_300 = 'fullfibre300';
export const TARIFF_FTTP_500 = 'fullfibre500';
export const TARIFF_FTTP_900 = 'fullfibre900';
export const TARIFFS_SOGEA = [TARIFF_FIBRE_40, TARIFF_FIBRE_80];
export const TARIFFS_FTTP = [
  TARIFF_FTTP_100,
  TARIFF_FTTP_300,
  TARIFF_FTTP_500,
  TARIFF_FTTP_900,
];
export const TARIFFS_FTTC = [TARIFF_ULTRA, TARIFF_ULTRA_PLUS];

export const PACKAGE_NAME_NO_ROUTER = "I'll use my own router";
export const PACKAGE_NAME_EERO = 'Multi-room package';
export const UI_PACKAGE_NAME_EERO = 'Whole Home Wi-Fi';
export const PACKAGE_NAME_UW_HUB = 'UW Wi-Fi Hub';
export const NO_ROUTER_ID = 'NO_ROUTER';
export const DEVICE_EERO = 'eero';

export const SET_BROADBAND_MORE_INFO = 'SET_BROADBAND_MORE_INFO';
export const SET_EERO_DIALOG_OPEN = 'SET_EERO_DIALOG_OPEN';

export const DEFAULT_NUMBER_OF_EEROS = 2;

export const TRY_BEFORE_YOU_BUY_PROMO = 'TBYB';

export const TECHNOLOGY_LLU = 'LLU';
export const TECHNOLOGY_LLX = 'LLX';
export const TECHNOLOGY_FTTC = 'FTTC';
export const TECHNOLOGY_SOGEA = 'SOGEA';
export const TECHNOLOGY_FTTP = 'FTTP';
export const TECHNOLOGY_ALL = 'All'; // meta to include all types
export const BENEFIT_CONTRACT = '18 months contract';
export const BENEFIT_CONTRACT_GENERIC =
  '18 months contract (monthly rolling for Standard)';
export const BENEFIT_GOLD = '10% Gold discount applied';
export const BENEFIT_LANDLINE = 'Landline included';
export const BENEFIT_LANDLINE_GENERIC =
  'Landline included (Ultra Plus, Ultra, Standard)';
export const BENEFIT_NO_PRICE_RISES = 'No mid-contract price rises';

export const NO_CALL_PACKAGE_ID = 'NO_CALL_PACKAGE';
export const NO_CALL_PACKAGE = {
  id: NO_CALL_PACKAGE_ID,
  name: 'No call package',
};

export const FEATURE_IDS = {
  NUMBER_WITHHELD: 1,
  EX_DIRECTORY: 2,
  BLOCK_SALES_CALLS: 3,
  CALLER_DISPLAY: 4,
  OFFPEAK_SAVER: 7,
};
